.content {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  title {
    font-size: 2rem;
    color: #555;
    text-align: center;
    margin-bottom: 15px;
  }
  
  text {
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: justify;
    color: #444;
  }
  