.main__container{
    width: 100%;
    min-height: 200px;
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    background-color: black;
    color:rgba(255, 255, 255, 0.895);
    /* z-index: -2000; */
    margin-top: 100px;
    margin-bottom: 0;
}

.lista{
    list-style-type: none;
    margin-left: 5%;
    width:190px;
}
.btn__displacement, li{
    padding: 0;
    margin: 0;
    background-color: transparent;
    -webkit-transition: all .2s;
    transition: all .2s;
    float: left;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-items: start;
    cursor: pointer;
    border-color: transparent;
}


.Image__container{
    margin-left:200px;
    display: flex;
    margin-bottom: 40px;
    margin-top: 50px;
}

.image{
    height: 200px
}

.footer__Wrapper__Div{
    display: flex;
    flex-direction: column;
    /* background-color: aqua; */
    justify-content: center;
    text-align: center;
    
}

.List__container :hover, .boton :hover, .botonDestino :hover{
    color: rgba(207, 151, 46, 0.944);
    cursor: pointer;
}
.list__container{
    /* background-color: aqua; */
    transform: translateX(-38px);
}

.contText{
    margin-left: 50px;
    /* background-color: aqua; */
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.text{
    font-size: 15px;
    /* background-color: aqua; */
    text-align:start;
    /* display: flex; */
    justify-content: center;
}

.btn__social__media{
    margin-left: 5px;
    border-radius: 50%;
    text-align: left !important ;
    width: 40px;
    height: 40px;
    /* background-color: brown; */
}

.table__container{
    height: 180px;
    width: 400px;
    margin-top: 0px;
    margin-left: 100px;
}

.Table{
    margin-top: 0px;  
}

@media all and (max-width: 700px){
    .main__container{
        display: flex;
        flex-direction: column;
        text-align: start;
        margin: auto;
        height: max-content;
        width: 100%;
    }
    
    .Image__container{
        width: 100%;
        margin-left:0px;
        display: flex;
        margin-top: 10px;
        margin-bottom: 50px;
    }
    
    .image{
        height: 80px
    }
    .image1{
        height: 30px;
        position: relative;
        
    }
    .List__container{
        display: flex;
        flex-direction: row;
        /* margin: auto; */
        /* transform: translateX(-50px); */
    }
    .list__container{
        height: 60px !important;
    }
    .List__container, .contText{
        width: 100%;
        /* margin-left: -50px; */
    }
    .btn__social__media{
        font-size: 30px;
    }

    .conText, .table__container,  .footer__Wrapper__Div{
        margin-top: 50px;
    }


    .botonNav, .text{
        font-size: 20px;
        text-align: start;
        /* margin: auto; */
        width :100%;
        margin-bottom: 10px;
    }
    .Table{
        display: none;  
        margin: auto;
    }
    .btn__displacement{
        /* margin: auto; */
        margin-top: 10px;
    }
    .btn__displacement:hover{
        background-color: rgba(207, 151, 46, 0.944)
    }
    .footer__Wrapper__Div{
        display: flex;
        text-align:start !important;
        width: 100%;
        margin-bottom: 20px;
        justify-content: flex-start !important;
    }

    .table__container{
        display: none;
    }

    .contText{
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
    }

    .text{
        text-align: justify;
    }

    .Image__container{
        margin-top: -10px !important;
    }

    .image{
        height: 200px;
    }
}