.main__container{
    display:flex;
    flex-wrap: wrap;
    align-content: center;
    width: 70%;
    margin: auto;
    margin-bottom: 50px;
    padding: 50px;
    background-color: black;
}

.image{
    width: 400px;
    height: 350px;
    display: flex;
    margin-top: 30px;
}
.image2{
    display: none;
}
.image__container{
    width: 100%;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}

.information__container{
    color:rgba(0, 0, 0, 0.631);
    margin-top: 1%;
    margin-left: 3%;
    border-color: black;
    width: 350px;
    padding-left: 30px;
    border-left: 3px solid rgba(207, 151, 46, 0.944);
    
}
.text1{
    border-bottom: 2px solid rgba(207, 151, 46, 0.944) ;
    padding-bottom: 20px;
}
.text, .text1{
    font-size:16px;
    color: white;
}
@media all and (max-width: 700px){

    .main__container{
        /* background-color: aqua; */
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 150px;
        padding: 10px;
    }

    .image__container,
    .direction__container{
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        text-align: start;
    }
    .image{
        display: none;
    }
    .image2{
        display: flex;
        width: 90%;
    }

    .information__container{
        margin-top: 30px;
    }
    .information__container, .image{
        margin-left: auto;
        margin-right: auto;
    }
}