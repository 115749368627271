:root {
    --background-color:rgba(206, 206, 206, 0.823)
    
}
.imagen{
    height: 80px
}
.imagen1{
    height: 40px;
    position: relative;
    top: -20px;
}


.contenedorDestino{  
    text-decoration: none; 
}

.botonDestino{
    position: relative;
    top: 35%;
    margin: 0px 3%;
    background-color: transparent;
    text-decoration: none;
    color: white;
    opacity:0.80;
    font-size: 15px;
    border-color: transparent;
}

a {
    text-decoration: none;
    color: white;
    opacity:0.80;
}

a:hover{
    opacity: 1;
}

.boton{
    position: relative;
    top: 35%;
    margin: 0px 5px;
    background-color: transparent;
    color: white;
    opacity:0.80;
    font-size: 15px;
    border-color: transparent;
    border-radius: 60% 60% 60% 60%;
    text-align: center;
}

.body{
    /* background-color: rgba(42, 42, 42, 0.51); */
    margin-top: 50px;
    min-height: 50vh;
    /* height: 100% !important; */
}
.main__container{
    background-color: rgba(146, 143, 143, 0.823);
    height: 100% !important;
    display: flex;
    flex-direction: column;

}

.layout{
    /* height: max-content; */
    height:100% !important;
    margin:0;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100vh;
    /* background-color: aqua; */
    /* padding-bottom: 300px ; */
    
}

.footer{
    /* margin-bottom: 0; */
    /* height: 100%; */
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%; */

}