.main__container{
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;
    text-align: center;
    /* background-color: aqua; */
    /* margin-bottom: 220px; */
}

.carousel__container{
    height: 100%;
    background-size: cover;
    background-color: black;
    text-align: center;
    
}
.carousel{
    background-color:black ;
    width: 95%;
    height: 700px;
    margin-left: auto;
    /* margin-top: auto;
    margin-bottom: auto; */
    margin-right: auto;
    text-align: center;
}

.carousel__Item{
    width: 100%;
    height: 650px;
    margin-bottom: 25px;
    margin-top: 25px;
}

.carousel__Item__Div{
    position: relative;
    width: 100%;
    height: 100%;
}

.image__Div{
    /* position: absolute; */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* z-index: 100; */
}


.image{
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
    object-fit: cover;
}

@media all and (max-width: 700px){
    .main__container{
        width:100%;
        /* height: 400px; */
        display: flex;
        
    }
    .carousel__container{
        width: 100%;
        align-items: start !important;
    }
    .carousel{
        height: 400px;
        align-items: start !important;
        
    }
    
    .text__container{
        background-color: transparent;
        font-size: 18px;
        color: white;
        width: 400px;
        margin-top: 10px !important;
        z-index: 100;
        height: 200px;
        border-radius: 5%/ 21%;
        position: relative !important;
        text-align: justify;
    }

    .schedule_button_div{
        width: max-content;
        margin-left: auto;
        margin-right: auto;
    }

    .carousel__Item__Div{
        /* position: absolute; */
        width: 100%;
        height: 400px;
        align-self: flex-start;
        top: 0;
        left: 0;
    }
    
    .carousel__Item{
        height: 400px;
    }
    .text__container__paragraph{
        width: 90%;
        font-size: 15px;
        

    }

    .text__container{
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }

    .image__Div{
        position: absolute;
        width: 100%;
        height: 400px;
        top: 0;
        left: 0;
        /* z-index: 100; */
    }
    
    .image{
        width: 100%;
        height: 350px;
        margin-top: 0;
        margin-left: 0;
        object-fit: cover;
    }
}