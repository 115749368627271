/* Featured_Services.module.css */
.main_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; /* Alineación en la parte inferior del contenedor */
    align-items: flex-end;
    height: 730px; /* Tamaño total de la ventana */
    /* background-color: aqua; */
    position: relative;
    overflow: hidden;
  }
  
  .carousel_container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 550px;
    background-color: white;
    height: 550px;
    flex-direction: column;
    border-radius: 90% 0 0 0;
    border-top: 10px solid #FEDB97;
    border-left: 10px solid #FEDB97;
    /* overflow: hidden; */
    margin-bottom: 0;
  }
  
  .circle {
    position: absolute;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    overflow: hidden; /* Para que la imagen se ajuste al círculo */
    cursor: pointer;
    border: 10px solid #FEDB97;
    
  }
  
  .button {
    width: 100px;
    height: 40px;
    margin: 10px;
  }
  
  .btn_container{
    position: absolute;
    right: 10px;
    bottom: 10px;
    /* background-color: bisque; */
  }

  .info{
    width: 70%;
    height: 90%;
    display: flex;
    flex-direction: column;
    /* background-color: aqua; */
    justify-content: center;
    align-items: center;
  }

  /* Featured_Services.module.css */
.title {
    font-size: 35px; /* Tamaño de fuente para el título */
    font-weight: bold; /* Negrita para el título */
    margin-bottom: 1rem; /* Espacio inferior para separar del texto */
    text-align: center; /* Alinear el título al centro */
    margin-bottom: 10px;
    color: #FEDB97;
    font-family: Bodoni MT ;
  }
  
  .text {
    font-size:25px; /* Tamaño de fuente para el texto */
    color: white; /* Color del texto */
    line-height: 1.5; /* Altura de línea para mejor legibilidad */
    text-align: center; /* Alinear el texto al centro */
    margin: 0 2rem; /* Espaciado horizontal para el texto */
    font-family: 'Arial', sans-serif;
  }
  @media all and (max-width: 700px){
    .main_container{
        flex-direction: column;
        height: auto;
    }
    .info{
        height: auto;
        width: 100%;
        padding: 10px;
        margin-bottom: 40px;
    }

    .circle {
        width: 100px;
        height: 100px;
    }

    .carousel_container{
        height: 300px;
        border-radius: 100% 0 0 0;
        width: 280px;
    }

    .text{
        font-size: 22px;
    }
  }