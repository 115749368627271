td {
    padding: 0px 10px;
}

table{
    margin-top: 7%;
    margin-left: 2%;
    margin-bottom: 50px;
    border-width: 1px;
    border-style: solid;
    border-color: white;
    color: white;
}

.book__Online__Div{
    /* margin-bottom: 100px; */
    min-height: 100vh;

}

.book__Online__Div__Wrapper{
    width: 750px;
    margin-left: auto;
    margin-right: auto;
}

.forms{
    width: 750px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border-width: 2px;
    border-color: black;
}


.forms__Label{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: start;
    color: #FEDB97;
    /* background-color: aqua; */
    margin-top: 10px;
}

.forms__Div{
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 750px;
    justify-content: space-between;
}

.forms__Time__Div{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.forms__Item{
    width: 200px;
    height: 40px;
    border-width: 2px;
    border-color: black;
    display: flex;
}

.forms__Sub__Div{
    height: 40px;
}

.forms__Item1{
    width: 80%;
    height: 40px;
    margin-left: 3px !important;
    border-width: 2px;
    border-color: black;
    border-radius: 10px;

}
option:hover{
    background-color:#fedc97d4 !important;
    border-color: transparent;
    color: white;
}

.alert{
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: rgba(238, 248, 208, 0.977);
}

.alertText{
    font-size: 17px;
}

.button{
    width: 150px;
    background-color: transparent !important;
    border: 2px solid #FEDB97;
    color:#FEDB97;
    transition: 2s;
    font-size: 20px;
}

.button:hover{
    background-color:#fedc97d4 !important;
    border-color: transparent;
    color: white;
}

.title{
    margin-top: 50px;
    color: #FEDB97;
    font-family: Bodoni MT ;
    font-size: 78px;
    /* width: 380px; */
    /* background-color: aqua; */
}

.forms__Text1,
.forms__Text{
    color: #FEDB97;
    margin-right: 5px;
    /* background-color: aqua; */
}

.forms__Text{
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    align-content: center;
}

@media all and (max-width: 700px){
    .book__Online__Div{
        width:100% !important;
        margin-left: auto;
        margin-right: auto;
    }

    .table, .book__Online__Div__Wrapper{
        margin-left: auto;
        margin-right: auto;
    }

    .book__Online__Div__Wrapper, .forms, .forms__Div{
        width: 350px;
    }
    
    .forms__Div{
        flex-direction: column !important;
        /* background-color: aqua; */
    }
    .forms__Item1{
        margin-left: 0;
    }

    .forms__Sub__Div{
        margin-bottom: 20px;
       width: 100%;
    }
    .forms__Text1{
        margin-left: -0px;
        margin-right: 10PX;
    }

}