
.main__container{
    width: 100%;
    height: 100%;
    display: flex;
    background-color: black;
    overflow: hidden !important;

    margin:auto;
    flex-direction: column;
    text-align: center;
}




/* bien */
.carousel__container{
    margin-top: 40px;
    margin-bottom: 45px;
    height: 100%;
    /* width: 100%; */
    background-size: cover;
    background-color: black;
    text-align: center;
    overflow: hidden !important;
}

/* bien */
.carousel{
    background-color:black;
    width: 95%;
    height: 700px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden !important;
    text-align: center;
    transition-duration: 1s;
}

.carousel__Item{
    width: 100%;
    /* background-color: aqua; */
    height: 700px;
    /* text-align: center; */
    margin-bottom: 50px;
    /* overflow: hidden !important; */
    /* margin-left: auto;
    margin-right: auto; */
    background-color: black;
    position: relative;
    
    
}

.carousel__Item__Div{
    position: relative;
    width: 100%;
    height: 100%;
    /* margin-left: auto;
    overflow: hidden !important;
    margin-right: auto; */

    background-color: #525252;
}

.image__container{
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* z-index: 100; */
}


.image{
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
    object-fit: cover;
    animation: zoom 30s infinite;
    
}

.table{
    position: absolute;
    overflow: hidden;
    width: 120%;
    height: 140%;
    top: -200px;
    left: -200px;
    
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.tr_container,
.td_container{
   
    width: 70px;
    height: 30px; 
    z-index: 100;
    /* background-color: rgb(60, 0, 255); */
    padding: 0;
    /* animation-delay: 1.5s; */
}

.inner_container{
    width: 100%;
    height: 100%;
    background-color: rgb(23, 23, 23);
    opacity: 1;
    animation: disapiar 1s;
    animation-delay: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes disapiar {
    0% {
        opacity: 1;
    }
    50% {
        background-color: rgb(83, 83, 83);
    }
    100% {
        opacity: 0;
      }
    
  }

@keyframes zoom {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
    
  }
 

@media all and (max-width: 700px){
    .main__container{
        width:100%;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
    }
    .carousel__container{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .carousel{
        height: 400px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .image{
        margin: 10px;
        width: 300px;
        height: 320px;
        margin-left: auto;
        margin-right: auto;
        zoom: 1.5;

    }

    .comments__container{
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }

    .tr_container,
    .td_container{
   
    width: 30px;
    height: 20px; 
    z-index: 100;
    padding: 0;
}

.table{
    position: absolute;
    overflow: hidden;
    width: 120%;
    height: 140%;
    top: -100px;
    left: -50px;
    
}

}
