.main__container{
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
}

.carousel__container{
    height: 100%;
    background-size: cover;
    /* background-color: black; */
    text-align: center;
}
.carousel{
    /* background-color:black ; */
    width: 95%;
    height: 700px;
    margin-left: auto;
    /* margin-top: auto;
    margin-bottom: auto; */
    margin-right: auto;
    text-align: center;
}

.carousel__Item{
    width: 100%;
    height: 650px;
    margin-bottom: 25px;
    margin-top: 25px;
}

.carousel__Item__Div{
    position: relative;
    width: 100%;
    height: 100%;
}

.image__Div{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* z-index: 100; */
}


.image{
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
    object-fit: cover;
}

.text__container{
    background-color: rgba(90, 90, 90, 0.664);
    font-size: 18px;
    color: white;
    width: 820px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    border-radius: 5%/ 21%;
}

.text__container__paragraph{
    width: 750px;
    margin: auto; 
    margin-top: 10px;
}

.schedule_button{
    margin-top: 20px !important;
    background-color:rgba(207, 151, 46, 0.944);
    border: none;
}

.schedule_button:hover{
    background-color:rgba(207, 151, 46, 0.944);
}

@media all and (max-width: 700px){
    .main__container{
        width:100%;
        
    }
    .carousel__container{
        width: 100%;
        align-items: start !important;
    }
    .carousel{
        height: 600px;
        align-items: start !important;
        
    }
    
    .text__container{
        background-color: transparent;
        font-size: 18px;
        color: white;
        width: 400px;
        margin-top: 210px !important;
        z-index: 100;
        height: 200px;
        border-radius: 5%/ 21%;
        position: relative !important;
        text-align: justify;
    }

    .schedule_button_div{
        width: max-content;
        margin-left: auto;
        margin-right: auto;
    }

    .carousel__Item__Div{
        position: absolute;
        width: 100%;
        height: 500px;
        align-self: flex-start;
        top: 0;
        left: 0;
    }
    
    .carousel__Item{
        height: 500px;
    }
    .text__container__paragraph{
        width: 90%;
        font-size: 15px;
    }

    .image__Div{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;
    }
    
    .image{
        width: 100%;
        height: 350px;
        margin-top: 0;
        margin-left: 0;
        object-fit: cover;
    }
}

.title {
    font-size: 55px; /* Tamaño de fuente para el título */
    font-weight: bold; /* Negrita para el título */
    margin-bottom: 1rem; /* Espacio inferior para separar del texto */
    margin-top: 30px;
    text-align: center; /* Alinear el título al centro */
    margin-bottom: 10px;
    color: #FEDB97;
    font-family: Bodoni MT ;
  }