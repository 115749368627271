.card__Header__Div{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.card{
    margin-top: 15px;
    margin-bottom: 15px;
}

.card__starts{
    display: flex;
    flex-direction: row;
}

.card_Header{
    background-color: white;
}

.commentsDiv{
    text-align: start;
}